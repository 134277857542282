@import "../../assets/css/global.css";

.fileinput {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  border-radius: var(--fileinput-radius);
  height: fit-content;
  width: fit-content;
  overflow: hidden;
  margin: 2.5vh 0;
}

.fileinput input {
  width: 0;
  height: 0.1px;
  opacity: 0.1px;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.fileinput label {
  font-size: var(--fileinput-font-size);
  font-weight: bold;
  color: var(--blue);
  background-color: var(--background);
  cursor: pointer;
  padding: 0.5em 1.5em;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  border: 0.2px solid rgba(0, 0, 0, 0.1);
  transition: ease-out 500ms;
  border-top-left-radius: var(--fileinput-radius);
  border-bottom-left-radius: var(--fileinput-radius);
  text-transform: uppercase;
}

.fileinput label:active {
  transform: scale(0.75);
}

.fileinput label p {
  margin: 0 0.5em;
}

.fileinput .span {
  font-size: var(--fileinput-font-size);
  font-weight: 800;
  color: var(--blue);
  background-color: var(--background);
  cursor: pointer;
  outline: 0;
  padding: 0.5em 1.5em;
  border: 0.2px solid rgba(0, 0, 0, 0.1);
  transition: ease-out 500ms;
  background-position: center;
  border-top-right-radius: var(--fileinput-radius);
  border-bottom-right-radius: var(--fileinput-radius);
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
}

.fileinput .span:active {
  transform: scale(0.75);
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-aspect-ratio: 1/1) {
}

@keyframes ripple {
  from {
    background-size: 0 0;
  }
  to {
    background-size: 1000% 1000%;
  }
}
