/* Extra small devices (portrait phones, less than 576px) */
@media only screen and (max-width: 575.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media only screen and (max-width: 767.98px) {
}

/* Medium devices (tablets, less than 992px) */
@media only screen and (max-width: 991.98px) {
}

/* Large devices (desktops, less than 1200px) */
@media only screen and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops)
 No media query since the extra-large breakpoint has no upper bound on its width */

:root {
  --background: whitesmoke;
  --background-above: white;
  --foreground: #2e3440;
  --blue: #5e81ac;
  --light-blue: #81a1c1;
  --red: #bf616a;
  --green: #a3be8c;
  --purple: #b48ead;
  --orange: #d08770;
  --yellow: #ebcb8b;

  --content-box-shadow: 0px 0px 90px 10px rgba(0, 0, 0, 0.5);
  --title-font-size: 45px;
  --title-padding: 0.5em 0.5em;
  --content-margin: 2.5vh 0;

  --header-height: 3em;
  --header-padding: 0.5em;
  --header-button-padding: 0em 1.5em;
  --header-button-font-size: 1em;
  --header-button-title-font-size: 1.25em;
  /* --header-button-text-shadow: 1px 1px 5px black; */
  --header-button-text-shadow: none;
  --header-background-color: var(--foreground);
  --header-solid-background-color: var(--header-background-color);
  --header-color: var(--background);

  --home-quote-width: 55vw;
  --home-quote-padding: 0 5vw;
  --home-quote-res-padding: 5vw;
  --home-avatar-size: 25vw;
  --home-avatar-res-size: 80vw;
  --home-greet-font-size: 1.75em;
  --home-intro-font-size: max(3em, 5vw);
  --home-info-font-size: 1.25em;

  --sidebar-font-size: 1em;

  --about-chapter-font-size: 1.25em;
  --about-section-font-size: 1em;

  --fileinput-radius: 5px;
  --fileinput-font-size: 1em;
}
